import AuthService from "../../services/AuthService";
import { authSlice } from "../reducers/AuthSlice";
import { userSlice } from "../reducers/UserSlice";
import type { AppDispatch } from "../store";
import type { ILoginData, RegistrationData, UserRole } from "../../models/authTypes";
import axios from "axios";
import { SendOtpSource } from "../../http/api";
import UserService from "../../services/UserService";

export const loginAction = (loginData: ILoginData) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setLoadingStatus(true));
        const { data } = await AuthService.login(loginData);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("token", data.accessToken);
        dispatch(userSlice.actions.setUserRole(data.role));
        const { data: userData } = await UserService.getUser();
        dispatch(userSlice.actions.setCurrentUser(userData));
        dispatch(authSlice.actions.setLoadingStatus(false));
        return data.role;
    } catch (error) {
        dispatch(authSlice.actions.setErrorMessage("Невірний логін або пароль"));
        throw error;
    }
};

export const sendVerifyCodeAction =
    (login: string, source: SendOtpSource) => async (dispatch: AppDispatch) => {
        try {
            dispatch(authSlice.actions.setLoadingStatus(true));
            const { data } = await AuthService.sendOtpCode(login, source);
            dispatch(authSlice.actions.setOtpCodeSuccess(data.OTP));
            return data.OTP;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 409) {
                dispatch(
                    authSlice.actions.setErrorMessage(
                        "Користувач з такими даними вже існує. Перейдіть на сторінку входу в акаунт."
                    )
                );
            } else {
                dispatch(
                    authSlice.actions.setErrorMessage("Не коректний номер телефону або емейл")
                );
            }
            throw error;
        }
    };

export const verifyOtpCodeAction =
    (login: string, code: string) => async (dispatch: AppDispatch) => {
        try {
            dispatch(authSlice.actions.setLoadingStatus(true));
            await AuthService.verifyOtpCode(login, code);
            dispatch(authSlice.actions.setLoadingStatus(false));
        } catch (error) {
            dispatch(authSlice.actions.setErrorMessage("Невірний або застарілий код"));
            throw error;
        }
    };

export const registrationAction = (formData: RegistrationData) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setLoadingStatus(true));
        const { data } = await AuthService.registration(formData);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("token", data.accessToken);
        dispatch(userSlice.actions.setUserRole(data.role));
        dispatch(authSlice.actions.verifyCodeSuccess(data.role));
        const { data: userData } = await UserService.getUser();
        dispatch(userSlice.actions.setCurrentUser(userData));
        dispatch(authSlice.actions.setLoadingStatus(false));
        return data.role;
    } catch (error) {
        dispatch(authSlice.actions.setErrorMessage("Помилка збереження данних"));
        throw error;
    }
};

export const changePasswordAction = (password: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.setLoadingStatus(true));
        const { data } = await AuthService.changePassword(password);
        localStorage.setItem("refreshToken", data.refreshToken);
        dispatch(userSlice.actions.setCurrentUser(data));
        dispatch(authSlice.actions.setLoadingStatus(false));
        return data.role;
    } catch (error) {
        dispatch(authSlice.actions.setErrorMessage("Помилка збросу пароля"));
        throw error;
    }
};

export const resetPasswordAction =
    (password: string, login: string, role: UserRole) => async (dispatch: AppDispatch) => {
        try {
            dispatch(authSlice.actions.setLoadingStatus(true));
            await AuthService.resetPassword(password, login);
            const { data } = await AuthService.login({ password, login, role });
            localStorage.setItem("refreshToken", data.refreshToken);
            localStorage.setItem("token", data.accessToken);
            dispatch(userSlice.actions.setUserRole(data.role));
            const { data: userData } = await UserService.getUser();
            dispatch(userSlice.actions.setCurrentUser(userData));
            dispatch(authSlice.actions.setLoadingStatus(false));
            return data.role;
        } catch (error) {
            dispatch(authSlice.actions.setErrorMessage("Помилка збросу пароля"));
            throw error;
        }
    };
