import { LogoutOutlined, UserOutlined, SettingOutlined } from "@ant-design/icons";
import { Avatar, Popover } from "antd";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../contexts/AuthContextProvider";
import { useAppSelector } from "../../hooks/redux";
import { UserRole } from "../../models/authTypes";
import { RoutesNames } from "../../routes/enum";
import { Language } from "./Language";

interface UserMenuProps {
    setOpenUserMenu: Dispatch<SetStateAction<boolean>>;
}

const UserMenu: FC<UserMenuProps> = ({ setOpenUserMenu }) => {
    const navigate = useNavigate();
    const { setCurrentUser } = useAuth();
    const { t } = useTranslation();

    const navigateToProfile = () => {
        setOpenUserMenu(false);
        navigate(`/${UserRole.ADMIN}/${RoutesNames.SETTINGS}`);
    };

    const logout = () => {
        setOpenUserMenu(false);
        setCurrentUser(undefined);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        navigate(RoutesNames.LOGIN);
    };

    return (
        <LinkList>
            <li className="profile" onClick={navigateToProfile}>
                <SettingOutlined />
                <span>{t("Settings")}</span>
            </li>
            <li className="logout" onClick={logout}>
                <LogoutOutlined />
                <span>{t("Logout")}</span>
            </li>
        </LinkList>
    );
};

interface AccountProps {
    isMobile: boolean;
}

export const AccountBlock: FC<AccountProps> = ({ isMobile }) => {
    const [openUserMenu, setOpenUserMenu] = useState<boolean>(false);
    const { user } = useAppSelector((state) => state.userReducer);

    const handleOpenUserMenu = (newOpen: boolean) => {
        setOpenUserMenu(newOpen);
    };

    const userName = `${user.lastName ?? ""} ${user.firstName ?? ""}`;

    return (
        <div className="user">
            <Popover
                id="user"
                arrow={false}
                content={<UserMenu setOpenUserMenu={setOpenUserMenu} />}
                placement="bottomLeft"
                trigger="click"
                open={openUserMenu}
                onOpenChange={handleOpenUserMenu}
            >
                <Avatar
                    style={{ background: "#412899", color: "#000" }}
                    src={user?.avatarUrl}
                    size={35}
                    icon={<UserOutlined />}
                />
                {!isMobile && <span className="user__name">{userName}</span>}
            </Popover>
            <Language />
        </div>
    );
};

const LinkList = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 5px;

    .profile,
    .logout {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 10px;
        padding: 5px;
        cursor: pointer;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: aliceblue;
        }
    }

    .profile {
        color: rgba(0, 0, 0, 0.85);
    }

    .logout {
        color: rgba(255, 77, 79, 1);
    }
`;
