import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { authSlice } from "../../store/reducers/AuthSlice";
import { RoutesNames } from "../../routes/enum";
import { checkEmailField } from "../../helpers/validation";
import { RowContainer } from "../../pages/login/styles";
import { CCheckbox, CInput, CButton } from "../common";
import { loginAction } from "../../store/actions/authActions";
import { showNotification } from "../../helpers/showNotification";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../models/authTypes";

export const LoginForm = () => {
    const { login, error, role, isLoading } = useAppSelector((state) => state.authReducer);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const getValueFromFields = (e: ChangeEvent<HTMLInputElement> | CheckboxChangeEvent) => {
        const name: string = e.target.name as string;
        let value: string | boolean = e.target.value;
        if (name === "savedInSystem") {
            value = e.target.checked;
            localStorage.setItem("savedInSystem", JSON.stringify(e.target.checked));
        }
        dispatch(authSlice.actions.updateLoginForm({ ...login, [name]: value }));
    };

    const onSubmitData = async () => {
        const data = {
            login: login.email,
            role: UserRole.ADMIN,
            password: login.password
        };

        try {
            await dispatch(loginAction(data));
            localStorage.setItem("savedInSystem", JSON.stringify(login.savedInSystem));
            navigate(`${RoutesNames.ADMIN}/${RoutesNames.CONSULTATION}`);
        } catch (error) {
            showNotification(
                t("You are not registered, you entered the wrong phone number, e-mail or password"),
                "error"
            );
        }
    };

    const checkEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.includes("@")) {
            const isValid = checkEmailField(value);
            !isValid &&
                dispatch(
                    authSlice.actions.setErrorMessage(t("The mailbox is not entered correctly"))
                );
        }
    };

    const onPressEnter = () => {
        onSubmitData();
    };

    return (
        <RowContainer>
            <CInput
                label={t("Phone/E-mail")}
                name="email"
                onChange={getValueFromFields}
                placeholder={t("Enter your phone number or email address")}
                onBlur={checkEmail}
                validateStatus={error ? "error" : ""}
            />

            <div className="password__field">
                <CInput
                    isPasswordField
                    label={t("Enter your password")}
                    name="password"
                    validateStatus={error ? "error" : ""}
                    onChange={getValueFromFields}
                    onPressEnter={onPressEnter}
                    placeholder={t("Enter your password")}
                />
            </div>
            <CCheckbox
                name="savedInSystem"
                checked={login.savedInSystem}
                title={<span>{t("Remember me for this device")}</span>}
                onChange={getValueFromFields}
            />
            <CButton loading={isLoading} onClick={onSubmitData}>
                {t("Login")}
            </CButton>
        </RowContainer>
    );
};
