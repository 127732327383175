import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import Breadcrumb from "./Breadcrumb";
import Header from "./Header";
import Navbar from "./Navbar";

export const RootLayout = () => {
    return (
        <Wrapper>
            <Layout className="root">
                <Header />
                <Layout>
                    <Navbar />
                    <ContentLayout>
                        <Breadcrumb />
                        <Layout.Content>
                            <Outlet />
                        </Layout.Content>
                    </ContentLayout>
                </Layout>
            </Layout>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    min-height: 100vh;

    .root {
        height: 100vh;
        min-height: 100vh;
        overflow-x: hidden;
    }
`;

const ContentLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-top: 0;
    padding-bottom: 20px;

    .ant-layout-content {
        padding: 15px;
    }

    .content__layout {
        background-color: #fff;
        border-radius: 15px;
        height: 100%;
        width: 100%;

        .ant-tabs-content-holder {
            overflow-y: auto;
        }
    }
`;
