import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react";

export const BreadcrumbContext = createContext<
    [PageParamsType, Dispatch<SetStateAction<PageParamsType>>]
>([{ routeName: "", pageTitle: "" }, () => {}]);

type PageParamsType = {
    routeName: string;
    pageTitle: string;
};

interface Props {
    children: ReactNode;
}

export const BreadcrumbContextProvider = ({ children }: Props) => {
    const [pageParams, setPageParams] = useState<PageParamsType>({ routeName: "", pageTitle: "" });

    return (
        <BreadcrumbContext.Provider value={[pageParams, setPageParams]}>
            {children}
        </BreadcrumbContext.Provider>
    );
};
