import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../hooks/redux";
import { UserRole } from "../models/authTypes";
import type { UserResponse } from "../models/userTypes";
import { RoutesNames } from "../routes/enum";
import UserService from "../services/UserService";
import { userSlice } from "../store/reducers/UserSlice";
import { caching } from "../utils/checkCash";

interface IUserContext {
    currentUser?: UserResponse;
    loading: boolean;
    setCurrentUser: Dispatch<SetStateAction<UserResponse | undefined>>;
}

const AuthContext = createContext<IUserContext>({ loading: true, setCurrentUser: () => {} });

interface Props {
    children: ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
    const [currentUser, setCurrentUser] = useState<UserResponse>();
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        caching();
        if (!currentUser) {
            UserService.getUser()
                .then(({ data }) => {
                    setCurrentUser(data);
                    dispatch(userSlice.actions.setCurrentUser(data));
                    setLoading(false);
                })
                .catch((err) => {
                    if (err?.response?.status === 401) {
                        navigate(RoutesNames.LOGIN);
                    }
                    setLoading(false);
                });
        }
        return () => {
            setCurrentUser(undefined);
        };
    }, []);

    const value = {
        currentUser,
        loading,
        setCurrentUser
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
