import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { IPagination } from "../../models/consultationsTypes";
import type { BlockStatusData, IUser } from "../../models/userTypes";

interface IUsersState {
    total: number;
    users: Array<IUser>;
    pagination: IPagination;
    isLoading: boolean;
}

const initialState: IUsersState = {
    total: 0,
    users: [],
    pagination: {
        skip: 0,
        take: 10
    },
    isLoading: false
};

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        },
        setUsers(state, action: PayloadAction<IUser[]>) {
            state.isLoading = false;
            state.users = action.payload;
        },
        updateUserStatus(state, action: PayloadAction<BlockStatusData>) {
            const currentUser = state.users.find((el) => el.id === action.payload.user_id);

            if (!currentUser) return;

            const currentIndex = state.users.findIndex((el) => el.id === action.payload.user_id);
            const updatedUser = {
                ...currentUser,
                activeStatus: action.payload.status,
                blockReason: action.payload.reason
            };
            state.users[currentIndex] = updatedUser;
            state.isLoading = false;
        }
    }
});

export default usersSlice.reducer;
