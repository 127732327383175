import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/AuthSlice";
import userReducer from "./reducers/UserSlice";
import consultationReducer from "./reducers/ConsultationSlice";
import requestsReducer from "./reducers/RequestsSlice";
import chatReducer from "./reducers/ChatSlice";
import medicalHistoryReducer from "./reducers/MedicalHistorySlice";
import prescriptionReducer from "./reducers/PrescriptionSlice";
import appointmentsReducer from "./reducers/AppointmentsSlice";
import financeReducer from "./reducers/FinanceSlice";
import usersReducer from "./reducers/UsersSlice";

const rootReducer = combineReducers({
    authReducer,
    userReducer,
    consultationReducer,
    requestsReducer,
    chatReducer,
    medicalHistoryReducer,
    prescriptionReducer,
    appointmentsReducer,
    financeReducer,
    usersReducer
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            })
    });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
