import type { AxiosResponse } from "axios";
import instance from "../http";
import { UserRoutes } from "../http/api";
import type { BlockStatusData, IUserProfile, UserResponse, UserStatus } from "../models/userTypes";

export default class UserService {
    static async getUser(): Promise<AxiosResponse<UserResponse>> {
        return instance.get(UserRoutes.PROFILE);
    }

    static async updateUser(data: IUserProfile): Promise<AxiosResponse<UserResponse>> {
        return instance.patch(UserRoutes.PROFILE, data);
    }

    static async uploadMedia(formData: FormData): Promise<AxiosResponse<UserResponse>> {
        return instance.post(UserRoutes.UPLOAD, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    }

    static async getPublicProfile(id: string): Promise<AxiosResponse> {
        return instance.get(UserRoutes.PUBLIC_PROFILE + "/" + id);
    }

    static async getUsers(pageSize: number, skip: number): Promise<AxiosResponse> {
        return instance.get(`${UserRoutes.USERS}?take=${pageSize}&skip=${skip}`);
    }

    static async changeUserStatus(data: BlockStatusData): Promise<AxiosResponse> {
        return instance.patch(UserRoutes.USERS, data);
    }

    static async pushNotification(): Promise<AxiosResponse> {
        return instance.post(UserRoutes.NOTIFICATION, {});
    }
}
