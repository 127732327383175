import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useBreadcrumb } from "../../hooks/useBreadcrumb";

const Breadcrumb = () => {
    const { breadcrumbs, navigateTo, pageTitle } = useBreadcrumb();
    const navigate = useNavigate();

    return (
        <Container>
            <div className="breadcrumbs-list">
                {breadcrumbs.map((item, index) => {
                    if (index === 0) return null;
                    return (
                        <span key={item.path} onClick={navigateTo(index)}>
                            {item.name} <u className="clash">/</u>
                        </span>
                    );
                })}
            </div>
            <h1>
                {breadcrumbs.length > 2 && (
                    <ArrowLeftOutlined onClick={() => navigate(-1)} style={{ fontSize: "18px" }} />
                )}
                {pageTitle}
            </h1>
        </Container>
    );
};

export default Breadcrumb;

const Container = styled.div`
    width: 100%;
    padding: 20px 15px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    box-shadow: rgb(81, 81, 81) 1px 0px 10px -6px;

    .breadcrumbs-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    h1 {
        color: var(--character-title-85, rgba(0, 0, 0, 0.85));
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    span {
        white-space: nowrap;
        cursor: pointer;
    }

    span:last-child {
        color: #000;

        .clash {
            display: none;
        }
    }

    @media (max-width: 470px) {
        max-width: 100vw;
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
