import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "../../components/login/LoginForm";
import { useAuth } from "../../contexts/AuthContextProvider";
import { UserRole } from "../../models/authTypes";
import { RoutesNames } from "../../routes/enum";
import { LoginContainer, LoginWrapper } from "./styles";

function LoginPage() {
    const { t } = useTranslation();
    const { currentUser } = useAuth();
    const navigate = useNavigate();

    if (currentUser?.id) {
        navigate(`${UserRole.ADMIN}/${RoutesNames.CONSULTATION}`);
    }

    return (
        <LoginWrapper>
            <div className="bg"></div>
            <div className="bg bg2"></div>
            <div className="bg bg3"></div>
            <LoginContainer>
                <img width={140} src="/images/VMS_logo.png" alt="logo" />
                <h1>{t("Log in to the administrator account")}</h1>
                <LoginForm />
            </LoginContainer>
        </LoginWrapper>
    );
}

export default LoginPage;
