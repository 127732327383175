import {
    DeleteOutlined,
    EyeOutlined,
    FileImageOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";
import { Image, Spin } from "antd";
import { FC, useState } from "react";
import styled from "styled-components";
import { uid } from "uid";
import type { IDocument } from "../../models/recordsTypes";

interface Props {
    removing?: boolean;
    files: Array<IDocument>;
    type: "photo" | "video";
    onRemove?: (fileId: string, consultationId?: string) => () => Promise<void>;
}

const FileList: FC<Props> = ({ files, type, onRemove, removing = false }) => {
    const [selectedFile, setSelectedFile] = useState<IDocument | null>(null);

    const onSelectFile = (file: IDocument) => () => {
        setSelectedFile(file);
    };

    const onClosePreview = () => setSelectedFile(null);

    const preview =
        type === "photo"
            ? {
                  visible: !!selectedFile,
                  scaleStep: 0.5,
                  src: selectedFile?.url,
                  onVisibleChange: onClosePreview
              }
            : {
                  onVisibleChange: onClosePreview,
                  visible: !!selectedFile,
                  imageRender: () => <video width="100%" controls src={selectedFile?.url} />,
                  toolbarRender: () => null
              };

    return (
        <Container>
            {removing && (
                <div className="file">
                    <Spin />
                </div>
            )}
            {!removing &&
                files.map((file) => {
                    const fileName =
                        file.filename.length < 15
                            ? file.filename
                            : file.filename.slice(0, 15) + "...";

                    return (
                        <div key={file.id + uid()} className="file">
                            <div className="file-info">
                                {type === "photo" ? <FileImageOutlined /> : <VideoCameraOutlined />}
                                <span>{fileName}</span>
                            </div>
                            <div className="file-actions">
                                <EyeOutlined
                                    style={{ color: "#1890FF" }}
                                    onClick={onSelectFile(file)}
                                />
                                {onRemove && (
                                    <DeleteOutlined
                                        style={{ color: "#FF4D4F" }}
                                        onClick={onRemove(file.id, file.consultation_id)}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            <Image style={{ display: "none" }} preview={preview} />
        </Container>
    );
};

export default FileList;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    .file {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        padding: 7px 0;

        &-info {
            display: flex;
            align-items: center;
            gap: 5px;

            & > span {
                color: #000;
            }
        }

        &-actions {
            display: flex;
            align-items: center;
            gap: 15px;

            & > span {
                color: #000;
            }

            svg {
                cursor: pointer;
            }
        }
    }
`;
