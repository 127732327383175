export enum RecipeStatus {
    DONE = "done",
    NEED_PAYMENTS = "need payments",
    REQUEST = "request",
    TMP = "template"
}

export interface IPatientPrescriptions {
    avatarUrl: string;
    firstName: string;
    id: string;
    lastName: string;
    recipes: IRecipeResponse[];
    requests: IRecipeRequest[];
    surName: string;
}

export interface IRecipeMedicamentState {
    name: string;
    unit: string;
    release_form: string;
    dosage: number;
    // period: number;
    period_text: string;
    receptions_per_day: number;
    diagnosis: string;
    comment: string;
    reciple_id?: string;
}

export interface IRecipe {
    chronic_diseases?: string | null;
    end_date?: string | null;
    country?: string | null;
    status: RecipeStatus;
    elements: Array<IRecipeMedicamentState>;
    consultation_id?: string;
    doctor_id: string;
    patient_id: string;
    request_id?: string;
    price: number;
}

export interface IRecipeResponse extends IRecipe {
    id: string;
    recipleNumber: number;
    createdAt: string;
    updatedAt: string;
    doctor?: {
        firstName: string;
        id: string;
        lastName: string;
        specializations: Array<string>;
        surName: string;
    };
    patient?: {
        birthdate: string;
        email: string;
        firstName: string;
        id: string;
        lastName: string;
        phone: string;
        surName: string;
    };
}

export interface IRecipeRequest extends IRecipe {
    createdAt: "2023-08-25T10:49:42.850Z";
    id: "2802bd06-4604-44ca-8532-bed9913dc6cc";
    recipleNumber: 1;
    updatedAt: "2023-08-25T10:49:42.850Z";
}
