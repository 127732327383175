export enum RoutesNames {
    // public routes
    LOGIN = "/",

    // private routes
    ADMIN = "/admin",
    APPOINTMENTS = "appointments",
    RECIPES = "prescription",
    CONSULTATION = "doctors",
    CHAT = "chat",
    MEDICAL_HISTORY = "medical-history",
    COMMISSION = "commission",
    PROMO_CODE = "promo-code",
    PATIENT_REPORT = "patient-report",
    DOCTOR_REPORT = "doctor-report",
    VERIFY = "doctor-verify",
    SETTINGS = "settings",
    USERS = "users"
}
