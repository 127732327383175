import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "../../models/authTypes";
import { IUserState, UserResponse, UserStatus } from "../../models/userTypes";

const initialState: IUserState = {
    isLoading: false,
    error: "",
    collapsedNavbar: false,
    user: {
        firstName: "",
        lastName: "",
        surName: "",
        country: "",
        city: "",
        birthdate: "",
        isFormActual: true,
        languages: [],
        sex: "",
        role: "",
        phone: "",
        email: "",
        activeStatus: UserStatus.NOT_ACTIVE,
        id: "",
        avatarUrl: "",
        originalUrl: "",
        publicUrl: "",
        refreshToken: "",
        createdAt: "",
        updatedAt: ""
    }
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserRole(state, action: PayloadAction<UserRole>) {
            state.user.role = action.payload;
            state.error = "";
        },
        setCollapsedNavbar(state, action: PayloadAction<boolean>) {
            state.collapsedNavbar = action.payload;
        },
        setCurrentUser(state, action: PayloadAction<UserResponse>) {
            state.user = action.payload;
            state.isLoading = false;
            state.error = "";
        },
        setEmail(state, action: PayloadAction<string>) {
            state.user.email = action.payload;
            state.error = "";
        },
        setLanguages(state, action: PayloadAction<string[]>) {
            state.user.languages = action.payload;
            state.error = "";
        },
        setBirthday(state, action: PayloadAction<string>) {
            state.user.birthdate = action.payload;
            state.error = "";
        },
        setSex(state, action: PayloadAction<string>) {
            state.user.sex = action.payload;
            state.error = "";
        },
        setError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        }
    }
});

export default userSlice.reducer;
