import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import uk_UA from 'antd/es/locale/uk_UA';
import en_EN from 'antd/es/locale/en_US';

import { setupStore } from './store/store';
import { Loader } from './components/common/Loader';
import { AuthContextProvider } from './contexts/AuthContextProvider';
import { BreadcrumbContextProvider } from './contexts/BreadcrumbContext';
import ConfirmContextProvider from './contexts/ConfirmContextProvider';
import { CustomConfirm } from './components/common';
import ErrorBoundary from './components/ErrorBoundary';
import { setLocaleLibs } from './utils/setLocaleLibs';

import './_custom.css';
import 'dayjs/locale/uk';
import 'dayjs/locale/en';

const store = setupStore();
const locale = setLocaleLibs();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <Suspense fallback={<Loader width="100vw" height="100vh" />}>
        <Provider store={store}>
          <AuthContextProvider>
            <ConfigProvider locale={locale === 'en' ? en_EN : uk_UA}>
              <BreadcrumbContextProvider>
                <ConfirmContextProvider>
                  <App />
                  <CustomConfirm />
                </ConfirmContextProvider>
              </BreadcrumbContextProvider>
            </ConfigProvider>
          </AuthContextProvider>
        </Provider>
      </Suspense>
    </ErrorBoundary>
  </BrowserRouter>,
);
