import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { IPagination } from "../../models/consultationsTypes";
import { IPatientPrescriptions, IRecipeResponse, RecipeStatus } from "../../models/recipeTypes";

interface IPrescriptionState {
    total: number;
    patients: Array<IPatientPrescriptions>;
    pagination: IPagination;
    isLoading: boolean;
    prescriptions: IRecipeResponse[];
    requests: IRecipeResponse[];
    prescriptionDetails: IRecipeResponse;
}

const initialState: IPrescriptionState = {
    total: 0,
    patients: [],
    pagination: {
        skip: 0,
        take: 10
    },
    isLoading: false,
    prescriptions: [],
    requests: [],
    prescriptionDetails: {
        chronic_diseases: "",
        end_date: "",
        country: "",
        status: RecipeStatus.DONE,
        elements: [],
        doctor_id: "",
        patient_id: "",
        id: "",
        price: 0,
        recipleNumber: 0,
        createdAt: "",
        updatedAt: "",
        doctor: {
            firstName: "",
            id: "",
            lastName: "",
            specializations: [],
            surName: ""
        },
        patient: {
            birthdate: "",
            email: "",
            firstName: "",
            id: "",
            lastName: "",
            phone: "",
            surName: ""
        }
    }
};

export const prescriptionSlice = createSlice({
    name: "prescription",
    initialState,
    reducers: {
        setDoctorsPatients(state, action: PayloadAction<IPatientPrescriptions[]>) {
            state.isLoading = false;
            state.patients = action.payload;
        },
        setPrescriptions(state, action: PayloadAction<IRecipeResponse[]>) {
            state.isLoading = false;
            state.prescriptions = action.payload;
        },
        setRequests(state, action: PayloadAction<IRecipeResponse[]>) {
            state.isLoading = false;
            state.requests = action.payload;
        },
        setPrescriptionDetails(state, action: PayloadAction<IRecipeResponse>) {
            state.isLoading = false;
            state.prescriptionDetails = action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        }
    }
});

export default prescriptionSlice.reducer;
