import { useEffect, useState } from "react";
import { i18n } from "../utils/i18n";

export const useLanguage = () => {
    const defaultLang = localStorage.getItem("editor_language") || "ua" || i18n.options?.lng;
    const [language, setLanguage] = useState<string>(defaultLang);

    const setCurrentLanguage = (lng: string) => {
        setLanguage(lng);
        i18n.changeLanguage(lng);
        localStorage.setItem("editor_language", lng);
    };

    useEffect(() => {
        setCurrentLanguage(defaultLang);
    }, [defaultLang]);

    return { language, setCurrentLanguage };
};
