import { Radio, RadioChangeEvent } from 'antd';
import { uid } from 'uid';
import { FC } from 'react';

interface Props {
  options: Array<{ label: string; value: string | number | boolean }>;
  onChange: (e: RadioChangeEvent) => void;
  label?: string;
  value?: any;
  name?: string;
  required?: boolean;
}

const CRadioButton: FC<Props> = ({
  required = false,
  options = [],
  label,
  value,
  name,
  onChange,
  ...props
}) => {
  return (
    <div className="radio-button-group">
      {label && (
        <label htmlFor="radio-button-group">
          {required && <span style={{ color: 'red', marginRight: '5px' }}>*</span>}
          {label}
        </label>
      )}
      <Radio.Group {...props} name={name} id="radio-button-group" value={value} onChange={onChange}>
        {options.map((el) => (
          <Radio key={uid()} value={el.value}>
            {el.label}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default CRadioButton;
