import { Navigate, Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { RootLayout } from "./components/RootLayout";
import LoginPage from "./pages/login";
import { RoutesNames } from "./routes/enum";
import { routes } from "./routes/routes";

function App() {
    return (
        <Routes>
            <Route path={RoutesNames.LOGIN} element={<LoginPage />} />
            <Route path={RoutesNames.ADMIN} element={<RootLayout />}>
                {routes.map((route) => (
                    <Route
                        key={route.id}
                        path={route.path}
                        element={<ProtectedRoute>{route.element}</ProtectedRoute>}
                    />
                ))}
            </Route>
            <Route path="*" element={<Navigate to={RoutesNames.LOGIN} />} />
        </Routes>
    );
}

export default App;
