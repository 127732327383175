import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { IObservationResponse, IPagination } from "../../models/consultationsTypes";
import type { IPatients } from "../../models/doctorTypes";
import {
    ConsultationStatus,
    IMedicalHistory,
    IObservationConsultation
} from "../../models/recordsTypes";

interface IRequestState {
    total: number;
    patients: Array<IPatients>;
    consultations: Array<IMedicalHistory>;
    observation: Array<IObservationResponse>;
    consultationObservation: IObservationConsultation;
    pagination: IPagination;
    isLoading: boolean;
}

const initialState: IRequestState = {
    total: 0,
    patients: [],
    consultations: [],
    observation: [],
    consultationObservation: {
        observation: {
            diagnosis: "",
            complaints: "",
            anamnesis: "",
            condition: "",
            skin_color: "",
            mucous_color: "",
            AD: "",
            pulse: 0,
            height: 0,
            weight: 0,
            waist: 0,
            urine_amount: 0,
            notes: "",
            patient_id: "",
            consultation_id: "",
            createdAt: "",
            doctor_id: "",
            id: "",
            updatedAt: ""
        },
        consultation: {
            date: "",
            status: ConsultationStatus.FUTURE,
            documents: [],
            signed: false
        }
    },
    pagination: {
        skip: 0,
        take: 10
    },
    isLoading: false
};

export const medicalHistorySlice = createSlice({
    name: "medical_history",
    initialState,
    reducers: {
        setDoctorsPatients(state, action: PayloadAction<IPatients[]>) {
            state.isLoading = false;
            state.patients = action.payload;
        },
        setPatientObservation(state, action: PayloadAction<IObservationResponse[]>) {
            state.observation = action.payload;
        },
        setConsultationObservation(state, action: PayloadAction<IObservationConsultation>) {
            state.consultationObservation = action.payload;
        },
        setConsultations(state, action: PayloadAction<IMedicalHistory[]>) {
            state.isLoading = false;
            state.consultations = action.payload;
        },
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        }
    }
});

export default medicalHistorySlice.reducer;
