import { FC, ReactElement, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContextProvider";
import { useAppSelector } from "../hooks/redux";
import { UserRole } from "../models/authTypes";
import { RoutesNames } from "../routes/enum";
import { Loader } from "./common/Loader";

interface Props {
    children: ReactElement<any, any>;
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
    const { loading } = useAuth();
    const { user } = useAppSelector((state) => state.userReducer);

    if (loading) {
        return <Loader width="100%" height="70vh" />;
    }

    if (user.role !== UserRole.ADMIN) {
        return <Navigate to={RoutesNames.LOGIN} />;
    }

    return <Suspense fallback={<Loader width="100%" height="70vh" />}>{children}</Suspense>;
};
