import dayjs from "dayjs";

export const initValidateState = {
    hasNumber: false,
    hasCapital: false,
    hasSpecialSymbol: false,
    hasCorrectLength: false,
    hasLowerLetter: false
};

export const getFieldRulesResult = (value: string) => {
    const isEnLetter = /[a-zA-Z]/.test(value);
    const hasNumber = !!parseInt(value.replace(/\D/g, ""));
    const hasCapital = RegExp(/\p{Lu}/, "u").test(value);
    const hasSpecialSymbol = RegExp(/[!@#№:;()_+$&*%]/).test(value);
    const hasCorrectLength = value.length >= 8;
    const hasLowerLetter = value !== value.toUpperCase();

    return {
        isEnLetter,
        hasNumber,
        hasCapital,
        hasSpecialSymbol,
        hasCorrectLength,
        hasLowerLetter
    };
};

export const checkEmailField = (value: string) => {
    return RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(value);
};

export const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

export const getValidDateInPast = (date: string) => dayjs() > dayjs(date);
