import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import type { IObservationResponse, IPagination } from "../../models/consultationsTypes";
import { IRecipeMedicamentState, IRecipeResponse, RecipeStatus } from "../../models/recipeTypes";
import {
    ConsultationStatus,
    type IMedicalHistory,
    type IObservationConsultation,
    type IPatientDetails,
    type IRecords
} from "../../models/recordsTypes";
import { UserStatus } from "../../models/userTypes";

interface IAppointmentsState {
    total: number;
    patients: Array<IRecords>;
    pagination: IPagination;
    isLoading: boolean;
    patientDetails: IPatientDetails;
    consultationRecipe: IRecipeResponse;
    consultationObservation: IObservationConsultation;
    observation: Array<IObservationResponse>;
    medicalHistory: Array<IMedicalHistory>;
}

const initialState: IAppointmentsState = {
    total: 0,
    patients: [],
    pagination: {
        skip: 0,
        take: 10
    },
    isLoading: false,
    patientDetails: {
        activeStatus: UserStatus.APPROVED,
        addresses: [],
        avatarUrl: "",
        birthdate: "",
        city: "",
        country: "",
        createdAt: "",
        email: "",
        firstName: "",
        id: "",
        languages: [],
        lastName: "",
        location_city: null,
        location_country: null,
        location_timezone: null,
        originalUrl: "",
        phone: "",
        policies: [],
        publicUrl: null,
        refreshToken: "",
        role: "",
        sex: "",
        surName: "",
        trustedPersons: [],
        updatedAt: "",
        healthForm: {
            sex: "",
            bePregnancy: false,
            pregnancyCount: 0,
            childrensCount: 0,
            abortion: false,
            abortionCount: 0,
            canceledPregnance: 0,
            smoking: "",
            alcohol: null,
            alcoholDescription: "",
            drugs: "",
            familyStatus: "",
            workStatus: "",
            lifeStyle: "",
            lifeStyleHours: "",
            arterialHypertension: null,
            parent_hypertension: null,
            mother60: "",
            parentsArterialHypertension: "",
            salt: null,
            saltLove: null,
            sleepHours: "",
            stressLevel: null,
            painLevel: null,
            anxietyLevel: null
        },
        dassForm: [],
        referrals: [],
        therapy: []
    },
    consultationRecipe: {
        id: "",
        recipleNumber: 0,
        chronic_diseases: null,
        end_date: null,
        country: null,
        status: RecipeStatus.DONE,
        consultation_id: "",
        price: 0,
        doctor_id: "",
        patient_id: "",
        createdAt: "",
        updatedAt: "",
        elements: []
    },
    consultationObservation: {
        observation: {
            diagnosis: "",
            complaints: "",
            anamnesis: "",
            condition: "",
            skin_color: "",
            mucous_color: "",
            AD: "",
            pulse: 0,
            height: 0,
            weight: 0,
            waist: 0,
            urine_amount: 0,
            notes: "",
            patient_id: "",
            consultation_id: "",
            createdAt: "",
            doctor_id: "",
            id: "",
            updatedAt: ""
        },
        consultation: {
            date: "",
            status: ConsultationStatus.FUTURE,
            documents: [],
            signed: false
        },
        comments: []
    },
    observation: [],
    medicalHistory: []
};

export const appointmentsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers: {
        setTotal(state, action: PayloadAction<number>) {
            state.total = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setPagination(state, action: PayloadAction<IPagination>) {
            state.pagination = action.payload;
        },
        setPatients(state, action: PayloadAction<IRecords[]>) {
            state.isLoading = false;
            state.patients = action.payload.sort(
                (a, b) => dayjs(b.updatedAt).valueOf() - dayjs(a.updatedAt).valueOf()
            );
        },
        setPublicPatientProfile(state, action: PayloadAction<IPatientDetails>) {
            state.isLoading = false;
            state.patientDetails = action.payload;
        },
        setConsultationRecipe(state, action: PayloadAction<IRecipeResponse>) {
            state.isLoading = false;
            state.consultationRecipe = action.payload;
        },
        setConsultationObservation(state, action: PayloadAction<IObservationConsultation>) {
            state.consultationObservation = action.payload;
        },
        setObservation(state, action: PayloadAction<IObservationResponse[]>) {
            state.observation = action.payload;
        },
        setMedicalHistory(state, action: PayloadAction<IMedicalHistory[]>) {
            state.medicalHistory = action.payload;
        }
    }
});

export default appointmentsSlice.reducer;
