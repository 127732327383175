import { Button, Grid, Layout } from "antd";
import { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { NavbarLink } from "./NavbarLink";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { userSlice } from "../../store/reducers/UserSlice";

const Navbar = () => {
    const [selectedLink, setSelectedLink] = useState<string[]>([]);
    const { collapsedNavbar } = useAppSelector((state) => state.userReducer);
    const dispatch = useAppDispatch();
    const { lg } = Grid.useBreakpoint();
    const { pathname } = useLocation();

    const toggleCollapsePanel = () => {
        dispatch(userSlice.actions.setCollapsedNavbar(!collapsedNavbar));
    };

    useEffect(() => {
        const pathNames = pathname.split("/");
        setSelectedLink([pathNames[pathNames.length - 1]]);
    }, [pathname]);

    return (
        <Container>
            <Layout.Sider
                className="menu__wrapper"
                style={{ background: "#fff" }}
                width={220}
                trigger={null}
                collapsible
                collapsed={collapsedNavbar}
            >
                <NavbarLink selectedLink={selectedLink} />
                {lg && (
                    <Button
                        type="text"
                        className="expand__button"
                        icon={collapsedNavbar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={toggleCollapsePanel}
                    />
                )}
            </Layout.Sider>
        </Container>
    );
};

export default Navbar;

const Container = styled.div`
    height: 100%;
    box-shadow: 1px 0 10px -6px #515151;

    .ant-menu-submenu-title {
        padding-left: 20px !important;
    }

    .ant-layout-sider {
        height: 100%;
    }

    .ant-layout-sider-children {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .ant-menu-item {
        width: 100%;
        margin-right: 3px;
        margin-left: 0;
        transition: all 0.2s ease-in-out;
        border-radius: 0;

        &-selected {
            width: 100%;
            background: #e6f7ff;
            border-right: 3px solid #1890ff;
            border-radius: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .expand__button {
        border-top: 1px solid #f0f0f0;
        font-size: 16px;
        width: 100%;
        height: 50px;
        text-align: start;
        padding: 0 15px;
    }
`;
