import { Popover } from "antd";
import clsx from "clsx";
import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { useLanguage } from "../../hooks/useLanguage";

interface Props {
    language: string;
    onChangeLanguage: (lang: string) => () => void;
}

const LanguageList: FC<Props> = ({ language, onChangeLanguage }) => {
    return (
        <Languages>
            <li
                className={clsx("list-item", language === "ua" && "active")}
                onClick={onChangeLanguage("ua")}
            >
                <img width={30} src="/images/ukraine-flag.png" alt="flag" />
                Українська
            </li>
            <li
                className={clsx("list-item", language === "en" && "active")}
                onClick={onChangeLanguage("en")}
            >
                <img width={30} src="/images/united-kingdom-flag.png" alt="flag" />
                English
            </li>
        </Languages>
    );
};

export const Language = () => {
    const { language, setCurrentLanguage } = useLanguage();
    const [openLanguageMenu, setOpenLanguageMenu] = useState<boolean>(false);
    const [locale, setLocale] = useState<string>(language);

    const handleOpenLanguageMenu = (newOpen: boolean) => {
        setOpenLanguageMenu(newOpen);
    };

    const onChangeLocaleLanguage = useCallback(
        (language: string) => () => {
            setLocale(language);
            setCurrentLanguage(language);
            setOpenLanguageMenu(false);
        },
        []
    );

    const imageSrc =
        locale === "ua" ? "/images/ukraine-flag.png" : "/images/united-kingdom-flag.png";

    return (
        <Popover
            id="language"
            arrow={false}
            content={<LanguageList language={locale} onChangeLanguage={onChangeLocaleLanguage} />}
            placement="bottomLeft"
            trigger="click"
            open={openLanguageMenu}
            onOpenChange={handleOpenLanguageMenu}
        >
            <img width={30} src={imageSrc} alt="flag" />
        </Popover>
    );
};

const Languages = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 5px;
    width: min-content;

    .list-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 10px;
        padding: 5px;
        cursor: pointer;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: aliceblue;
        }
    }

    .active {
        font-weight: 500;
        color: rgba(24, 144, 255, 1);
        background: rgba(230, 247, 255, 1);
    }
`;
