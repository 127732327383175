import styled, { keyframes } from "styled-components";

const slide = keyframes`
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
`;

export const LoginWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    .bg {
        animation: ${slide} 3s ease-in-out infinite alternate;
        background-image: linear-gradient(-60deg, #6c3 50%, #09f 50%);
        bottom: 0;
        left: -50%;
        opacity: 0.5;
        position: fixed;
        right: -50%;
        top: 0;
        z-index: -1;
    }

    .bg2 {
        animation-direction: alternate-reverse;
        animation-duration: 4s;
    }

    .bg3 {
        animation-duration: 5s;
    }
`;

export const LoginContainer = styled.div`
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    background-color: #fff;
    padding: 25px;
    border-radius: 22px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);

    h1 {
        font-weight: 500;
        font-size: 26px;
        margin-bottom: 30px;
        text-align: center;
    }
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;

    .ant-checkbox {
        align-self: flex-start;
        margin-top: 4px;
    }

    .password__field {
        p {
            text-align: end;
            .link {
                text-decoration: underline;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    .code__field {
        .error {
            text-align: start;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ff4d4f;
        }
        .description {
            text-align: start;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.45);
        }

        .send__code {
            text-decoration: underline;
            font-weight: 500;
            color: #1890ff;
            cursor: pointer;
        }

        .repeat__sending {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            pointer-events: none;
        }
    }
`;
